import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { PageProps } from '@lp-src/utils/local-types';
import { Col, Grid, H1, Row } from '@web/atomic';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '../components/org.layout/clean-layout.component';
import { QuizB2bResultsRow } from '../modules/quiz-b2b/quiz-b2b-results-row.component';

interface ResultsQuizzB2bPageQueryParams {
  company?: string;
}

const ResultsQuizzB2bPage: React.FunctionComponent<PageProps> = (props) => {
  const queryParams = useQueryParams<ResultsQuizzB2bPageQueryParams>();
  return (
    <CleanLayout>
      <SEO
        robots={'noindex'}
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Resultados do questionário de insônia',
          description: 'Entenda sua insônia e confira uma dica personalizada que preparamos pra você',
        }}
      />
      <Grid>
        <Row center={'xs'} mt>
          <Col xs={12} sm={12} md={9} lg={8}>
            <H1 center>Resultados do Questionário </H1>
          </Col>
        </Row>

        <QuizB2bResultsRow cta={queryParams.company && { external: true, href: `/${queryParams.company}` }} />
      </Grid>
    </CleanLayout>
  );
};

export default ResultsQuizzB2bPage;

export const query = graphql`
  query ResultsQuizzB2bQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
